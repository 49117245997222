document.addEventListener('DOMContentLoaded', function() {
    
    const el = document.getElementsByClassName('js-gotop')[0];    
    let scroll_pos;
    
    const action = function() {
        scroll_pos = window.pageYOffset || window.scrollY;
        scroll_pos > 100 ? el.classList.add('is-visible') : el.classList.remove('is-visible');
    };

    action();
    el ? window.addEventListener('scroll', action) : false;

}, false);

import InView from 'inview';

document.addEventListener('DOMContentLoaded',function() {
            
    const anims = document.getElementsByClassName('anim');
    
    window.inViewInit = function() {
        
        const isInView = function(el) {
            let bottomOfWindow = (window.pageYOffset || window.scrollY) + window.innerHeight;
            
            if ( el.getBoundingClientRect().top + (window.pageYOffset || window.scrollY) < bottomOfWindow ) {
                return true;
            }
        };      

        for (let i = 0; i < anims.length; i++) {

            if (isInView(anims[i])) {
                anims[i].classList.add('anim--visible');
            }

            const inview = InView(anims[i], function(isInView) {
                if (isInView) {

                    anims[i].classList.add('anim--visible');
                    this.destroy();
    
                }
            });
        }
    };

}, false);

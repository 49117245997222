const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded',function() {

    const nav = document.getElementsByClassName('js-nav')[0],
          hamburger = document.getElementsByClassName('js-hamburger')[0],
          menu = document.getElementsByClassName('js-menu')[0];

    const init = function() {

        window.hideMenu = function() {
            document.getElementsByClassName('js-nav')[0].classList.remove('is-visible');
            document.getElementsByClassName('js-hamburger')[0].classList.remove('is-active');
            
            document.removeEventListener('click', clickOutside);

            setTimeout(function() {
                document.getElementsByClassName('js-nav')[0].classList.remove('is-block');
            }, 400);
            
            enableBodyScroll(document.getElementsByClassName('js-menu')[0]);
        };

        const showMenu = function() {
 
            nav.classList.add('is-block');
            hamburger.classList.add('is-active');

            setTimeout(function() {
                nav.classList.add('is-visible');
                document.addEventListener('click', clickOutside);
            }, 10);

            disableBodyScroll(menu);
        };

        const toggleMenu = function(e) {
            nav.classList.contains('is-visible') ? hideMenu() : showMenu();
        };

        
        hamburger.addEventListener('click', toggleMenu);
        
        const clickOutside = function(e) {
            if (!e.target.closest('.js-nav')) {
                hideMenu();
        	}
        };
        

        // Hide menu on ESC

        document.addEventListener('keydown', function(evt) {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hideMenu();
            }
        });

        const checkWindowSize = function() {
            if (window.innerWidth > 768) {
                hideMenu();
                window.removeEventListener('resize', checkWindowSize);
            } else {
                
            }
        };

        window.addEventListener('resize', checkWindowSize);
    };

    nav ? init() : false;

}, false);
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded',function() {
    
    window.runScroll = function(el) {
        
        let offsetTop = document.querySelector(el).offsetTop;
  
        scroll({
            top: offsetTop,
            behavior: "smooth"
        })
    };

    const gtt = document.querySelectorAll("[data-target]");
    
    if (gtt.length > 0) {
        const action = function(e) {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        	
        	document.getElementsByClassName('js-nav')[0].classList.contains('is-visible') ? window.hideMenu() : false;
            runScroll(e.currentTarget.dataset.target);
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
